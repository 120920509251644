<template>
  <v-stepper v-model="stepperGuestAccount" flat>
    <v-stepper-items>
      <v-stepper-content step="0" class="no-padding">
        <!-- {{ guest }} -->
        <v-card-title>
          <span class="text-h5">Informations professionnelles</span>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-form ref="form" v-model="validityFormPassword" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="new-account-lastname"
                  dense
                  prepend-inner-icon="mdi-account"
                  outlined
                  :rules="basicRules"
                  :error-messages="lastnameErrorMessage"
                  v-model="editedItem.lastname"
                  label="Nom utilisateur"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="new-account-firstname"
                  dense
                  prepend-inner-icon="mdi-account"
                  outlined
                  :rules="basicRules"
                  :error-messages="firstnameErrorMessage"
                  v-model="editedItem.firstname"
                  label="Prénom utilisateur"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  outlined
                  dense
                  label="Lieu de travail principal"
                  :items="agency_etablishment"
                  item-value="id"
                  item-text="city"
                  v-model="searchEtablishment"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="new-account-street"
                  dense
                  prepend-inner-icon="mdi-account"
                  outlined
                  v-model="editedItem.mainWorkplaceStreet"
                  label="Adresse"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  id="new-account-city"
                  messages="Entrez un code postal"
                  :search-input.sync="searchCityProfessionnal"
                  :items="cityItemsProfessionnal"
                  outlined
                  v-model="editedItem.mainWorkplaceCity"
                  :value="editedItem.mainWorkplaceZip"
                  label="Ville"
                  clearable
                  hide-details
                  hide-selected
                  dense
                  no-filter
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title> Entrez un code postal </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </template></v-autocomplete
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="new-account-email"
                  readonly
                  dense
                  prepend-inner-icon="mdi-gmail"
                  outlined
                  :rules="emailRules"
                  :error-messages="emailErrorMessage"
                  v-model="guest.email"
                  label="Mail professionnel"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="new-account-phone"
                  dense
                  prepend-inner-icon="mdi-phone"
                  outlined
                  :rules="phoneNumberRules"
                  :error-messages="phoneNumberErrorMessage"
                  v-model="editedItem.phoneNumberProfessional"
                  label="N° professionnel"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-stepper-content>

      <v-stepper-content step="1" class="no-padding">
        <v-card-title>
          <span class="text-h5">Informations personnelles</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  id="new-account-street"
                  dense
                  prepend-inner-icon="mdi-account"
                  outlined
                  v-model="editedItem.street"
                  label="Adresse personnelle"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  id="new-account-city"
                  messages="Entrez un code postal"
                  :search-input.sync="searchCity"
                  :items="cityItems"
                  outlined
                  v-model="editedItem.city"
                  :value="editedItem.zip"
                  label="Ville"
                  clearable
                  hide-details
                  hide-selected
                  dense
                  no-filter
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title> Entrez un code postal </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </template></v-autocomplete
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="new-account-email"
                  dense
                  prepend-inner-icon="mdi-gmail"
                  outlined
                  :rules="emailRules"
                  :error-messages="emailErrorMessage"
                  v-model="editedItem.personnalEmail"
                  label="Mail personnel"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="new-account-phone"
                  dense
                  prepend-inner-icon="mdi-phone"
                  outlined
                  :rules="phoneNumberRules"
                  :error-messages="phoneNumberErrorMessage"
                  v-model="editedItem.personnalPhoneNumber"
                  label="N° personnel"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  dense
                  prepend-inner-icon="mdi-cogs"
                  outlined
                  v-model="editedItem.idMaritalStatus"
                  :items="[
                    {
                      id: 1,
                      designation: 'Célibataire',
                    },

                    {
                      id: 2,
                      designation: 'En concubinage',
                    },
                    {
                      id: 3,
                      designation: 'Marié(e)',
                    },
                    {
                      id: 4,
                      designation: 'Pacsé(e)',
                    },
                    {
                      id: 5,
                      designation: 'Divorcé(e)',
                    },
                    {
                      id: 6,
                      designation: 'Veuf(ve)',
                    },
                  ]"
                  item-text="designation"
                  item-value="id"
                  label="Situation familiale"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="mt-n3">
                <v-switch inset v-model="editedItem.hasChildren" label="Avez-vous des enfants ?"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-text
          class="no-padding mb-10"
          v-if="
            editedItem.hasChildren ||
            editedItem.idMaritalStatus === 3 ||
            editedItem.idMaritalStatus === 2 ||
            editedItem.idMaritalStatus === 4
          "
        >
          <v-form>
            <v-simple-table dense class="no-padding">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Nature du lien</th>
                    <th class="text-center">Nom de famille</th>
                    <th class="text-center">Prénom</th>
                    <th class="text-center">Contact d'urgence</th>
                    <th class="text-center">Téléphone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(familyMember, index) in editedItem.familyMembers" v-bind:key="index">
                    <td class="text-left">
                      <v-select
                        class="mt-6"
                        dense
                        :id="`input-family-${index}`"
                        outlined
                        v-model="familyMember.id_account_family_link"
                        :items="familyOptions"
                        item-text="designation"
                        item-value="id"
                      ></v-select>
                    </td>
                    <td class="text-left">
                      <v-text-field
                        ref="newTextField"
                        outlined
                        class="mt-6"
                        dense
                        v-model="familyMember.lastname"
                        single-line
                      ></v-text-field>
                    </td>

                    <td class="text-left">
                      <v-text-field
                        ref="newTextField"
                        outlined
                        class="mt-6"
                        dense
                        v-model="familyMember.firstname"
                        single-line
                      ></v-text-field>
                    </td>

                    <td class="text-center justify-center">
                      <v-switch class="ml-7" inset v-model="familyMember.isEmergencyContact"> </v-switch>
                    </td>
                    <td class="text-left">
                      <v-text-field
                        v-if="familyMember.isEmergencyContact"
                        ref="newTextField"
                        outlined
                        class="mt-6"
                        dense
                        v-model="familyMember.phoneNumber"
                        single-line
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row>
              <v-col cols="12" md="4">
                <v-btn style="text-transform: none" elevation="0" @click="addLine(editedItem.familyMembers)">
                  <v-icon left>mdi-plus-circle</v-icon> Ajouter un membre à la famille
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-stepper-content>

      <v-stepper-content step="2" class="no-padding">
        <v-card-title>
          <span class="text-h5">Relation contractuelle avec l'entreprise</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="validityFormPassword" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    id="input-date-entry"
                    dense
                    prepend-inner-icon="mdi-phone"
                    outlined
                    type="date"
                    v-model="editedItem.entryDate"
                    label="Date d'entrée dans l'entreprise"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    id="select-type-contract"
                    dense
                    prepend-inner-icon="mdi-cogs"
                    outlined
                    v-model="editedItem.idTypeContract"
                    :items="typeContracts"
                    label="Type de relation"
                    item-text="designation"
                    item-value="id"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content :id="`item-type-contract-${item.id}`">
                        <v-list-item-title>{{ item.designation }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    id="select-type-employment"
                    dense
                    prepend-inner-icon="mdi-cogs"
                    outlined
                    :items="typeEmployments"
                    item-text="designation"
                    item-value="id"
                    label="Type d'emploi"
                    v-model="editedItem.idTypeEmployment"
                    ><template v-slot:item="{ item }">
                      <v-list-item-content :id="`item-type-employment-${item.id}`">
                        <v-list-item-title>{{ item.designation }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    id="select-type-job"
                    dense
                    prepend-inner-icon="mdi-cogs"
                    outlined
                    v-model="editedItem.idTypeJob"
                    :items="typeJobs"
                    item-text="designation"
                    item-value="id"
                    label="Poste"
                    ><template v-slot:item="{ item }">
                      <v-list-item-content :id="`item-type-job-${item.id}`">
                        <v-list-item-title>{{ item.designation }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    id="select-type-convention"
                    dense
                    prepend-inner-icon="mdi-cogs"
                    outlined
                    :items="[
                      {
                        id: 1,
                        designation: `Convention collective des agents généraux d'assurance`,
                      },
                      {
                        id: 2,
                        designation: `Convention collective du courtage d'assurance`,
                      },
                    ]"
                    item-text="designation"
                    item-value="id"
                    label="Convention"
                    v-model="editedItem.idCollectiveAgreement"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content :id="`item-type-qualification-${item.id}`">
                        <v-list-item-title>{{ item.designation }}</v-list-item-title>
                      </v-list-item-content>
                    </template></v-select
                  >
                </v-col>
              </v-row>
              <v-row v-if="editedItem.idConvention === 1">
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    id="select-type-qualification"
                    dense
                    prepend-inner-icon="mdi-cogs"
                    outlined
                    :items="typeQualifications"
                    item-text="designation"
                    item-value="id"
                    label="Classification du poste"
                    v-model="editedItem.idTypeQualification"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content :id="`item-type-qualification-${item.id}`">
                        <v-list-item-title>{{ item.designation }}</v-list-item-title>
                      </v-list-item-content>
                    </template></v-select
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-stepper-content>

      <v-stepper-content step="3" class="no-padding">
        <v-card-title>
          <span class="text-h5">Création de votre compte</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="input-password"
                  dense
                  outlined
                  v-model="editedItem.password"
                  :error-messages="passwordErrorMessage"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordRules.required, passwordRules.min]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Mot de passe"
                  counter="12"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="input-password-validation"
                  dense
                  outlined
                  v-model="editedItem.passwordValidation"
                  :error-messages="passwordErrorMessage"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordRules.required, passwordRules.min]"
                  :type="showPassword ? 'text' : 'password'"
                  label=" Validation du mot de passe"
                  hint="Vos deux mots de passe doivent correspondre"
                  counter="12"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-stepper-content>
    </v-stepper-items>

    <v-card-actions>
      <v-btn id="btn-last" v-if="stepperGuestAccount > 0" text @click="stepperGuestAccount--">retour</v-btn>
      <v-spacer></v-spacer>

      <v-btn id="btn-next" v-if="stepperGuestAccount < 3" text @click="stepperGuestAccount++">suivant</v-btn>
      <!-- <v-btn
                        id="btn-validate"
                        v-if="stepperAccountTutorial === 2"
                        class="white--text"
                        color="EoleGreen"
                        @click="putAccountById"
                      >
                        Valider
                      </v-btn> -->
      <v-btn
        id="btn-validate"
        v-if="stepperGuestAccount === 3"
        class="white--text"
        color="EoleGreen"
        @click="controlForm"
      >
        Terminer
      </v-btn>
    </v-card-actions>
  </v-stepper>
</template>

<script>
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
import config from "@/views/config/config"

export default {
  name: "GuestAccount",

  props: {
    guest: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Ce champ est requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 12 || "Le mot de passe doit contenir au moins 12 caractères",
    },
    emailRules: [
      v => !!v || "Une adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide : example@gmail.com",
    ],
    searchEtablishment: null,
    editedItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "utilisateur",
      email: "",
      personnalPhoneNumber: "",
      phoneNumberProfessional: "",

      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
      familyMembers: [],
      idMaritalStatus: 1,
      street: "",
      city: null,
      zip: "",
      mainWorkplaceZip: "",
      mainWorkplaceCity: "",
      mainWorkplaceStreet: "",
      personnalPhoneNumber: "",
      personnalEmail: "",
      idCollectiveAgreement: null,
      entryDate: "",
      password: "",
      passwordValidation: "",
      hasChildren: false,
    },
    stepperGuestAccount: 0,
    account: {
      id: null,
      token: null,
    },
    password: null,
    passwordConfirm: null,
    agency_etablishment: [],
    cityItems: [],
    cityItemsProfessionnal: [],
    searchCity: "",
    searchCityProfessionnal: "",
    typeEmployments: [],
    typeContracts: [],
    typeJobs: [],
    typeQualifications: [],
  }),
  async created() {
    this.fetchAgency()
    this.fetchType()

    this.editedItem.id = this.guest.id
  },

  watch: {
    searchEtablishment() {
      this.editedItem.mainWorkplaceStreet = this.agency_etablishment.find(
        element => element.id === this.searchEtablishment,
      ).street
      this.editedItem.mainWorkplaceCity = this.agency_etablishment.find(
        element => element.id === this.searchEtablishment,
      ).city
      this.searchCityProfessionnal = this.agency_etablishment.find(
        element => element.id === this.searchEtablishment,
      ).zip
    },

    searchCityProfessionnal(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItemsProfessionnal = res.map(item => item.nom)
              this.editedItem.mainWorkplaceZip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
    searchCity(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.editedItem.zip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
  },

  methods: {
    controlForm() {
      if (this.editedItem.password.length >= 12 && this.editedItem.password === this.editedItem.passwordValidation) {
        if (this.$refs.form.validate()) {
          this.putAccountById()
          this.passwordErrorMessage = ""
          this.password = ""
          this.passwordValidation = ""
        }
      } else {
        this.passwordErrorMessage = "Les mots de passes doivent correspondre"
      }
    },
    async putAccountById() {
      const data = this.editedItem
      const formData = new FormData()
      formData.append("place", "putGuestAccount")
      formData.append("id", data.id)
      formData.append("lastname", data.lastname)
      formData.append("firstname", data.firstname)
      formData.append("email", this.guest.email)
      formData.append("phoneNumber", data.phoneNumberProfessional)
      formData.append("idTypeContract", data.idTypeContract)
      formData.append("idTypeJob", data.idTypeJob)
      formData.append("idTypeEmployment", data.idTypeEmployment)
      formData.append("idTypeQualification", data.idTypeQualification)
      formData.append("entryDate", data.entryDate)
      formData.append("street", data.street)
      formData.append("city", data.city)
      formData.append("zip", data.zip)
      formData.append("idMaritalStatus", data.idMaritalStatus)
      formData.append("mainWorkplaceZip", data.mainWorkplaceZip)
      formData.append("mainWorkplaceCity", data.mainWorkplaceCity)
      formData.append("mainWorkplaceStreet", data.mainWorkplaceStreet)
      formData.append("personnalPhoneNumber", data.personnalPhoneNumber)
      formData.append("personnalEmail", data.personnalEmail)
      formData.append("idCollectiveAgreement", data.idCollectiveAgreement)
      formData.append("password", data.password)

      data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

      try {
        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
        this.$toast.success(
          `Vous venez de modifier le compte ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} avec succès.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )

        window.location.href = "/auth/login"
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchType() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/type_contract`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        if (data.code === 1) {
          data.type_employments.forEach(employment => {
            this.typeEmployments.push(employment)
          })
          data.type_contracts.forEach(contract => {
            this.typeContracts.push(contract)
          })
          data.type_jobs.forEach(job => {
            this.typeJobs.push(job)
          })
          data.type_qualifications.forEach(qualification => {
            this.typeQualifications.push(qualification)
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchAgency() {
      this.agency_etablishment = []

      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/agencies/${this.guest.id_agency}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          this.agency_etablishment = data.agency[0].etablishment
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
