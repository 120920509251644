<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">A propos de vous</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-form ref="form" v-model="validityFormPassword" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                id="new-account-lastname"
                dense
                prepend-inner-icon="mdi-account"
                outlined
                :rules="basicRules"
                :error-messages="lastnameErrorMessage"
                v-model="editedItem.lastname"
                label="Nom utilisateur"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                id="new-account-firstname"
                dense
                prepend-inner-icon="mdi-account"
                outlined
                :rules="basicRules"
                :error-messages="firstnameErrorMessage"
                v-model="editedItem.firstname"
                label="Prénom utilisateur"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="mt-n5">
              <v-text-field
                id="input-password"
                dense
                outlined
                v-model="editedItem.password"
                :error-messages="passwordErrorMessage"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[passwordRules.required, passwordRules.min]"
                :type="showPassword ? 'text' : 'password'"
                label="Mot de passe"
                counter="12"
                @click:append="showPassword = !showPassword"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mt-n5">
              <v-text-field
                id="input-password-validation"
                dense
                outlined
                v-model="editedItem.passwordValidation"
                :error-messages="passwordErrorMessage"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[passwordRules.required, passwordRules.min]"
                :type="showPassword ? 'text' : 'password'"
                label=" Validation du mot de passe"
                hint="Vos deux mots de passe doivent correspondre"
                counter="12"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn id="btn-validate" class="white--text" color="EoleBlue" @click="controlForm" block> Terminer </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
import config from "@/views/config/config"

export default {
  name: "GuestSocial",

  props: {
    social: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    editedItem: {},
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Ce champ est requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 10 || "Le mot de passe doit contenir au moins 12 caractères",
    },
    emailRules: [
      v => !!v || "Une adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide : example@gmail.com",
    ],
  }),
  async created() {
    this.editedItem = this.social
  },

  watch: {},
  methods: {
    async controlForm() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.password === this.editedItem.passwordValidation) {
          this.putAccountById()
        } else {
          this.$toast.error(`Les mots de passe ne correspondent pas`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } else {
        this.$toast.error(`Veuillez remplir correctement les champs`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async putAccountById() {
      const id_agency = this.social.id_agency
      const idSocial = this.social.id

      const data = this.editedItem

      try {
        const response = await fetch(`${config.apiUri}/agencies/${id_agency}/socials/${idSocial}`, {
          method: "PUT",
          body: JSON.stringify({
            lastname: data.lastname,
            firstname: data.firstname,
            password: data.password,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.social.token}`,
          },
        })

        if (response.ok) {
          this.$toast.success(
            `Vous venez de modifier le compte ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} avec succès.`,
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )

          window.location.href = "/auth/login"
        } else {
          this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }

        // window.location.href = "/auth/login"
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
