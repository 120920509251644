<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner" :style="e1 > 1 ? 'width: 54rem;' : 'width: 54rem;'">
      <v-card class="rounded-xl" style="box-shadow: 0 0 60px -20px #000000d9">
        <v-row>
          <v-col cols="12" md="4" class="no-padding d-flex">
            <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
              <v-card-title>
                <v-img
                  :src="require('@/assets/images/logos/anavel_logo.png')"
                  max-height="150px"
                  max-width="120px"
                  alt="logo"
                  contain
                ></v-img>
              </v-card-title>
              <v-card-title class="text-center justify-center muller-capitalized">Mes Services Assurance</v-card-title>
              <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
              <v-card-text class="no-padding">
                <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
              </v-card-text>
              <!-- <v-footer color="EoleBlueLighten" class="text-center justify-center black--text">
                  <small class="caption EoleBlue--text"
                    >{{ appName }} {{ version }} &copy; {{ new Date().getFullYear() }}</small
                  >
                </v-footer> -->
            </v-card>
          </v-col>
          <v-col cols="12" md="8" class="pl-0 d-flex no-padding">
            <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
              <v-stepper v-model="e1" flat class="no-padding flex-grow-1">
                <v-stepper-items class="no-padding">
                  <v-stepper-content class="no-padding" step="1">
                    <v-card-title class="text-center">Pour commencer, identifiez-vous</v-card-title>

                    <v-card-text class="no-padding flex-grow-1">
                      <v-list two-line dense>
                        <v-list-item-group v-model="idUserTypeChoose" active-class="EoleBlue--text" class="no-padding">
                          <template v-for="(item, index) in userTypes">
                            <v-list-item
                              :id="`connection-${index}`"
                              :disabled="item.disabled"
                              :key="item.icon"
                              @click="
                                userTypeChoose = item.value
                                e1++
                              "
                            >
                              <template v-slot:default="{ active }">
                                <v-list-item-icon>
                                  <v-avatar color="EoleBlueLighten" size="48">
                                    <i
                                      style="font-size: 1.3rem"
                                      :class="[
                                        ' fa-solid ',
                                        item.icon,
                                        'fa-1x',
                                        item.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                      ]"
                                    ></i>
                                  </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.type"></v-list-item-title>

                                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-list-item>

                            <v-divider v-if="index < userTypes.length - 1" :key="index"></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-stepper-content>

                  <v-stepper-content v-if="userTypeChoose === 'sponsor'" class="no-padding" step="2">
                    <v-row class="mt-3">
                      <v-col cols="12" md="1" class="text-left">
                        <v-btn
                          small
                          text
                          @click="
                            e1 = 1
                            userTypeChoose = ''
                          "
                        >
                          <v-icon left>mdi-arrow-left</v-icon>
                          Retour
                        </v-btn>
                      </v-col>
                      <v-col align-self="center" cols="12" md="6" class="text-left ml-2 mt-3"> </v-col>
                    </v-row>
                    <v-card-title class="d-flex align-center justify-center">
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="170px"
                        alt="logo"
                        contain
                        class="me-3"
                      ></v-img>
                      <v-chip outlined color="EoleGreen" class="text-center justify-center">Espace parrainage</v-chip>
                    </v-card-title>
                    <v-card-text class="py-5">
                      <v-form>
                        <v-text-field
                          :rules="sponsorCodeRules"
                          v-model="sponsorCode"
                          outlined
                          label="Code de parrainage"
                          placeholder="12345678"
                          :hint="`Le code de parrainage vous a été envoyé par email`"
                          class="mb-3"
                        >
                        </v-text-field>
                        <v-btn
                          :disabled="isDisabledSponsorCode"
                          block
                          color="primary"
                          class="mt-6"
                          @click="verifySponsorCodeConnection(sponsorCode)"
                        >
                          Entrer sur la plateforme
                        </v-btn>
                      </v-form>
                    </v-card-text>
                    <v-card-text class="d-flex align-center">
                      <v-divider></v-divider>
                      <span class="mx-5">ou</span>
                      <v-divider></v-divider>
                    </v-card-text>
                  </v-stepper-content>

                  <v-stepper-content v-else-if="userTypeChoose === 'insurer'" class="no-padding" step="2">
                    <v-row class="mt-3">
                      <v-col cols="12" md="1" class="text-left">
                        <v-btn
                          small
                          text
                          @click="
                            e1 = 1
                            userTypeChoose = ''
                          "
                        >
                          <v-icon left>mdi-arrow-left</v-icon>
                          Retour
                        </v-btn>
                      </v-col>
                      <v-col align-self="center" cols="12" md="6" class="text-left ml-2 mt-3"> </v-col>
                    </v-row>
                    <v-card-title class="d-flex align-center justify-center">
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="170px"
                        alt="logo"
                        contain
                        class="me-3"
                      ></v-img>
                      <v-chip v-if="userTypeChoose === 'insurer'" color="EoleGreen" outlined>Courtier</v-chip>
                      <v-chip v-else color="EoleGreen" outlined>Client</v-chip>
                    </v-card-title>
                    <v-card-text class="py-5" v-if="!lostPassword">
                      <loading
                        transition="fade"
                        :active.sync="loadingAccount"
                        :can-cancel="false"
                        :is-full-page="false"
                        loader="spinner"
                        color="#efb639"
                        :opacity="0.5"
                      >
                      </loading>
                      <v-form>
                        <v-text-field
                          dense
                          id="email"
                          v-model="email"
                          outlined
                          label="Email"
                          placeholder="john@example.com"
                          hide-details
                          class="mb-3"
                        ></v-text-field>

                        <v-text-field
                          dense
                          id="password"
                          v-model="password"
                          outlined
                          :type="isPasswordVisible ? 'text' : 'password'"
                          label="Mot de passe"
                          placeholder="············"
                          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                          hide-details
                          @click:append="isPasswordVisible = !isPasswordVisible"
                          v-on:keyup.enter="connect(email, password)"
                        ></v-text-field>

                        <div class="d-flex align-center justify-space-between flex-wrap">
                          <v-checkbox v-model="saveData" label="Se souvenir de moi" hide-details class="me-3 mt-1">
                          </v-checkbox>

                          <a @click="lostPassword = true" class="mt-1"> Mot de passe oublié ?</a>
                        </div>
                        <v-btn
                          id="btn-connection"
                          :disabled="isDisabled"
                          block
                          color="primary"
                          class="mt-6"
                          @click="connect(email, password)"
                        >
                          Continuer <v-icon right>mdi-arrow-right</v-icon>
                        </v-btn>
                      </v-form>
                    </v-card-text>
                    <v-card-text v-else>
                      <lost-password-component :email="email"></lost-password-component>
                    </v-card-text>
                  </v-stepper-content>
                  <v-stepper-content v-else-if="userTypeChoose === 'social'" class="no-padding" step="2">
                    <v-row class="mt-3">
                      <v-col cols="12" md="1" class="text-left">
                        <v-btn
                          small
                          text
                          @click="
                            e1 = 1
                            userTypeChoose = ''
                          "
                        >
                          <v-icon left>mdi-arrow-left</v-icon>
                          Retour
                        </v-btn>
                      </v-col>
                      <v-col align-self="center" cols="12" md="6" class="text-left ml-2 mt-3"> </v-col>
                    </v-row>
                    <v-card-title class="d-flex align-center justify-center">
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="170px"
                        alt="logo"
                        contain
                        class="me-3"
                      ></v-img>
                      <v-chip color="EoleGreen" outlined>Social</v-chip>
                    </v-card-title>
                    <v-card-text class="py-5" v-if="!lostPassword">
                      <loading
                        transition="fade"
                        :active.sync="loadingAccount"
                        :can-cancel="false"
                        :is-full-page="false"
                        loader="spinner"
                        color="#efb639"
                        :opacity="0.5"
                      >
                      </loading>
                      <v-form>
                        <v-text-field
                          dense
                          id="email"
                          v-model="email"
                          outlined
                          label="Email"
                          placeholder="john@example.com"
                          hide-details
                          class="mb-3"
                        ></v-text-field>

                        <v-text-field
                          dense
                          id="password"
                          v-model="password"
                          outlined
                          :type="isPasswordVisible ? 'text' : 'password'"
                          label="Mot de passe"
                          placeholder="············"
                          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                          hide-details
                          @click:append="isPasswordVisible = !isPasswordVisible"
                          v-on:keyup.enter="connect(email, password)"
                        ></v-text-field>

                        <div class="d-flex align-center justify-space-between flex-wrap">
                          <v-checkbox v-model="saveData" label="Se souvenir de moi" hide-details class="me-3 mt-1">
                          </v-checkbox>

                          <a @click="lostPassword = true" class="mt-1"> Mot de passe oublié ?</a>
                        </div>
                        <v-btn
                          id="btn-connection"
                          :disabled="isDisabled"
                          block
                          color="primary"
                          class="mt-6"
                          @click="connect(email, password)"
                        >
                          Continuer <v-icon right>mdi-arrow-right</v-icon>
                        </v-btn>
                      </v-form>
                    </v-card-text>
                    <v-card-text v-else>
                      <lost-password-component :email="email"></lost-password-component>
                    </v-card-text>
                  </v-stepper-content>
                  <v-stepper-content v-else class="no-padding" step="2">
                    <div v-if="!!guest">
                      <v-card-title>Bienvenue {{ guest.email }}</v-card-title>
                      <v-card-subtitle
                        >Vous avez été invité à rejoindre la plateforme Mes Services Assurance par la société
                        <strong>{{ guest.agency_name }}</strong></v-card-subtitle
                      >
                      <v-card-text class="no-padding">
                        <guest-account-component :guest="guest"></guest-account-component>
                      </v-card-text>
                    </div>
                    <div v-if="!!social">
                      <v-card-title>Bienvenue {{ social.email }}</v-card-title>
                      <v-card-subtitle
                        >Vous avez été invité à rejoindre la plateforme Mes Services Assurance par la société
                        <strong>{{ social.agency_name }}</strong></v-card-subtitle
                      >
                      <v-card-text class="no-padding">
                        <social-account-component :social="social"></social-account-component>
                      </v-card-text>
                    </div>
                  </v-stepper-content>
                  <v-stepper-content class="no-padding" step="3" v-if="userData.pincode != null">
                    <v-card color="EoleBlue" class="pb-5">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="1" class="text-left">
                            <v-btn
                              color="white"
                              small
                              text
                              @click="
                                e1 = 1
                                userTypeChoose = ''
                              "
                            >
                              <v-icon left>mdi-arrow-left</v-icon>
                              Retour
                            </v-btn>
                          </v-col>
                          <v-col align-self="center" cols="12" md="6" class="text-left ml-2"> </v-col>
                          <v-col cols="12" md="4" class="text-right">
                            <!-- <v-avatar color="white" size="60" class="ml-auto">
                              <v-img
                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                max-height="150px"
                                max-width="50px"
                                alt="logo"
                                contain
                                class="mx-auto"
                              >
                              </v-img>
                            </v-avatar> -->
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card style="z-index: 999" class="rounded-t-xl mt-n5" outlined>
                      <v-card-text>
                        <v-card-title>Entrez votre code confidentiel</v-card-title>
                        <vue-pincode ref="pincodeInput" @pincode="checkPincode" />
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="3" v-else>
                    <v-card-text class="text-center">
                      <v-alert type="error" color="EoleError" text dense prominent>
                        Attention en cas de perte ou d'oubli du code, une intervention de votre administrateur sera
                        nécessaire pour le réinitialiser.
                      </v-alert>
                    </v-card-text>

                    <v-card style="z-index: 999" class="rounded-t-xl mt-n5" outlined>
                      <v-card-text>
                        <v-card-title>Choisissez votre code confidentiel</v-card-title>
                        <vue-pincode ref="pincodeFirst" @pincode="firstPincode" />
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="4">
                    <v-card style="z-index: 999" class="rounded-t-xl mt-10" outlined>
                      <v-card-text>
                        <v-card-title>Confirmez votre code confidentiel</v-card-title>
                        <vue-pincode ref="pincodeSecond" @pincode="checkNewPincode" />
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
              <v-card-text class="d-flex align-center" v-if="e1 < 3">
                <v-divider></v-divider>
                <span class="mx-5">ou</span>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions class="d-flex justify-center" v-if="e1 < 3">
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <span class="me-2">Nouveau sur notre plateforme ?</span>
                  <router-link :to="{ name: 'pages-register-office' }">Créer un compte</router-link>
                </v-card-text>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-card-actions class="d-flex justify-center">
          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">Nouveau sur notre plateforme ?</span>
            <router-link :to="{ name: 'pages-register-office' }">Créer un compte</router-link>
          </v-card-text>
        </v-card-actions> -->
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js"

import { ref } from "@vue/composition-api"
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import VuePincode from "@/layouts/components/VuePincode_pinterest.vue"
import LostPasswordComponent from "./LostPassword.vue"
import GuestAccountComponent from "./GuestAccount.vue"
import SocialAccountComponent from "./SocialAccount.vue"
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

import { socket } from "@/utils/socket.js"

export default {
  data: () => ({
    social: null,
    guest: null,
    idUserTypeChoose: null,
    isHovered: false,
    loadingAccount: false,
    lostPassword: false,
    company: {
      id: null,
      name: null,
    },
    showPassword: false,
    editedItem: {
      idCustomer: null,
      lastname: "",
      firstname: "",
      email: "",
      phoneNumber: "",
      password: "",
      passwordConfirm: "",
    },
    defaultItem: {
      lastname: "",
      firstname: "",
      email: "",
      phoneNumber: "",
      password: "",
      passwordConfirm: "",
    },
    validityFormPassword: true,
    basicRules: [v => !!v || "Ce champ est requis"],

    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 12 || "Minimum 12 caractères",
    },
    emailRules: [
      v => !!v || "Une adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide : example@gmail.com",
    ],
    sponsorCodeValid: false,
    sponsorCodeRules: [v => !!v || "Code parrain obligatoire"],
    sponsorCode: "",
    userTypeChoose: "",
    userTypes: [
      {
        type: "Espace client",
        value: "client",
        hovered: false,
        icon: "fa-users-viewfinder",
        description: "Vous êtes un client ? Connectez-vous à votre espace client",
        disabled: true,
      },
      {
        type: "Espace parrainé",
        value: "sponsor",
        hovered: false,
        icon: "fa-user-tie",
        description: "Vous avez été parrainé ? Connectez-vous pour bénéficier d'offres exclusives",
        disabled: false,
      },
      {
        type: "Espace courtier",
        value: "insurer",
        hovered: false,
        icon: "fa-suitcase",
        description: "Vous êtes un courtier ? Connectez-vous à votre espace courtier",
        disabled: false,
      },
      {
        type: "Espace partenaire",
        value: "social",
        hovered: false,
        icon: "fa-people-roof",
        description: "Vous êtes un cabinet social, un avocat, un expert comptable ? Connectez-vous à votre espace",
        disabled: false,
      },
      {
        type: "Espace candidat",
        value: "social",
        hovered: false,
        icon: "fa-handshake",
        description: "Vous êtes candidat pour rejoindre les équipes ? Connectez-vous à votre espace",
        disabled: false,
      },
    ],
    sessionExpire: 240,
    sessionItems: [
      {
        text: "1 minute",
        value: 1,
      },
      {
        text: "1 heure",
        value: 60,
      },
      {
        text: "2 heures",
        value: 120,
      },
      {
        text: "4 heures",
        value: 240,
      },
      {
        text: "8 heures",
        value: 480,
      },
      {
        text: "12 heures",
        value: 720,
      },
    ],
    confidential: {
      pincode: null,
      pincodeConfirm: null,
    },
    gradeData: [],
    linkData: [],
    userData: [],
    step: 0,
    version: config.informations.version,
    appName: config.applicationName,
    saveData: false,
    email: null,
    password: null,
    waitTimeout: false,
    date: null,
    time: null,
    resume: [],
    typeUser: null,
    e1: 1,
    connectionOptions: [
      {
        id: 1,
        name: "Espace courtage",
        img_name: "pro_2",
      },
      {
        id: 2,
        name: "Espace client",
        img_name: "pro_3",
      },
    ],
  }),
  components: {
    Loading,
    VuePincode,
    LostPasswordComponent,
    GuestAccountComponent,
    SocialAccountComponent,
  },
  async created() {
    if (localStorage.getItem("email") != null && localStorage.getItem("data_save") == "true") {
      this.email = localStorage.getItem("email")
      this.saveData = true
    }

    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has("id")) {
      this.sponsorCode = urlParams.get("id")
      this.sponsorCodeValid = true
      this.e1 = 2
      this.lostPassword = true
    }

    if (urlParams.has("token")) {
      localStorage.clear()

      this.lostPassword = true
      this.userTypeChoose = "insurer"
    }

    if (urlParams.has("firstconnection")) {
      if (urlParams.has("type")) {
        const type = urlParams.get("type")
        let id = urlParams.get("id")
        let token = urlParams.get("token")

        switch (type) {
          case "social":
            this.userTypeChoose = "guest"

            this.fetchSocial(id, token)

            break
          case "guest":
            this.userTypeChoose = "guest"

            this.fetchGuest(id, token)
            break
          default:
            this.userTypeChoose = "insurer"
            break
        }
      }
    }
  },
  computed: {
    isDisabled: function () {
      return !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email,
      )
    },
    isDisabledSponsorCode: function () {
      // disable if length < 12
      return this.sponsorCode.length < 12
    },
  },
  methods: {
    validateFormPassword() {
      if (this.editedItem.password.length >= 12 && this.editedItem.password === this.editedItem.passwordConfirm) {
        if (this.$refs.form.validate()) {
          this.createSponsoredAccount(this.editedItem)
        }
      } else {
        this.passwordErrorMessage = "Les mots de passes doivent correspondre"
      }
    },
    async updatePincode() {
      const postData = {
        pincode: this.confidential.pincodeConfirm,
      }
      try {
        const response = await fetch(`${config.apiUri}/accounts/${this.userData.userId}/set-pincode`, {
          mode: "cors",
          method: "PUT",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (response.ok) {
          // redirect to dashboard

          this.$toast.success(
            "Les deux codes pin correspondent.\nVous pourrez vous connecter désormais grâce à celui-ci.",
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )
        } else {
          this.$toast.error(
            "Impossible d'enregistrer votre nouveau code pin. Le code pin ne correspond pas au premier rentré.\nVeuillez réessayer.",
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )
        }

        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (e) {
        console.log(e)
      }
    },

    firstPincode(pincode) {
      if (pincode.length > 5) {
        this.$refs.pincodeFirst.triggerSuccess()
        this.confidential.pincode = this.$refs.pincodeFirst.pincode
        this.nextStep(3)
      }
    },
    secondPincode(pincode) {
      if (pincode.length > 5) {
        this.confidential.pincodeConfirm = this.$refs.pincodeSecond.pincode
        return true
      }
    },

    async updatePincodeSocial() {
      console.log(this.userData)
      try {
        const response = fetch(`${config.apiUri}/socials/${this.userData.userId}/set-pincode`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pincode: this.confidential.pincodeConfirm,
          }),
        })

        if (response.ok) {
          this.$toast.success(
            "Les deux codes pin correspondent.\nVous pourrez vous connecter désormais grâce à celui-ci.",
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )
        }
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (e) {
        console.log(e)
      }
    },

    checkNewPincode(pincode) {
      if (this.secondPincode(pincode)) {
        if (this.confidential.pincode === this.confidential.pincodeConfirm) {
          this.$refs.pincodeSecond.triggerSuccess()

          switch (this.userTypeChoose) {
            case "insurer":
              this.updatePincode()
              break
            case "social":
              this.updatePincodeSocial()
              break
          }

          this.$toast.success(
            "Les deux codes pin correspondent.\nVous pourrez vous connecter désormais grâce à celui-ci.",
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )
        } else {
          this.$refs.pincodeSecond.triggerMiss()
          this.$toast.error(
            "Impossible d'enregistrer votre nouveau code pin. Le code pin ne correspond pas au premier rentré.\nVeuillez réessayer.",
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )
        }
      }
    },

    async checkPincode(pincode) {
      try {
        // Dispatching the login action with pincode, userId, and userType
        this.$store
          .dispatch("login", {
            userId: this.userData.userId,
            pincode,
            type: this.userTypeChoose,
            password: this.password,
          })
          .then(async () => {
            // Trigger success animation on pincode input
            this.$refs.pincodeInput.triggerSuccess()

            // Determine the route based on user type
            const route = this.userTypeChoose === "social" ? "/office" : "/dashboard"

            // Attempt to navigate to the determined route
            try {
              await this.$router.push(route)
            } catch (routeError) {
              // If the primary route fails, attempt the secondary route for 'social' type
              if (this.userTypeChoose === "social") {
                await this.$router.push("/lawyer")
              }
            }

            // Show success toast message
            this.$toast.success("Connexion autorisée.", {
              position: "bottom-right",
              timeout: 2500,
            })
          })
          .catch(error => {
            this.$refs.pincodeInput.triggerMiss()
            this.$toast.error(error.message, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
      } catch (error) {
        // Trigger failure animation on pincode input
        this.$refs.pincodeInput.triggerMiss()

        // Log the error and show error toast message
        console.error("PinCode error:", error)
        this.$toast.error(`Connexion impossible. ${error.message}\nVeuillez réessayer.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    // if (pincode == this.userData.pincode) {
    //   this.waitTimeout = false
    //   this.applyDataStorage(this.sessionExpire)
    //   this.$refs.pincodeInput.triggerSuccess()

    //   setTimeout(() => {
    //     socket.emit("userConnected", localStorage.getItem("complete_name"), localStorage.getItem("user_id"))
    //     this.$toast.success("Connexion autorisée.", {
    //       position: "bottom-right",
    //       timeout: 2500,
    //     })

    //     this.userTypeChoose === "social"
    //       ? this.$router.push("/office").catch(() => {})
    //       : this.$router.push("/dashboard").catch(() => {})
    //   }, 2500)
    // } else {
    //   this.$refs.pincodeInput.triggerMiss()
    //   console.error("PinCode error !")

    //   this.$toast.error("Connexion impossible. Votre code pin est incorrect.\nVeuillez réessayer.", {
    //     position: "bottom-right",
    //     timeout: 3000,
    //   })
    // }
    chooseTypology(typology) {
      this.typeUser = typology
      this.pushResume("Typologie", typology)

      this.nextStep(1)
    },

    pushResume(title, value) {
      this.resume.push({
        title: title,
        value: value,
      })
    },

    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
    },
    lastStep(n) {
      this.e1 = n - 1
    },
    wip() {
      this.$toast.warning(`Fonctionnalité en cours de développement.`, {
        position: "bottom-right",
        timeout: 5000,
      })
    },
    getCurrentDate() {
      var today = new Date()
      var date = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
      var time = today.getHours() + ":" + today.getMinutes()
      this.date = date
      this.time = time
    },
    async fetchSocial(id, token) {
      try {
        const response = await fetch(`${config.apiUri}/socials/${id}/token/${token}`, {
          mode: "cors",
          method: "GET",
        })
        const data = await response.json()

        if (response.ok) {
          this.social = data
          alert(JSON.stringify(data))
        } else {
          window.location = "/auth/login"

          this.$toast.error("Connexion impossible. Votre code parrain est incorrect.\nVeuillez réessayer.", {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchGuest(id, token) {
      console.log(id)
      try {
        const response = await fetch(`${config.apiUri}/guest/${id}/${token}`, {
          mode: "cors",
          method: "GET",
        })
        const data = await response.json()

        if (data.code === 1) {
          this.guest = data.account[0]
        } else {
          window.location = "/auth/login"

          this.$toast.error("Connexion impossible. Votre code parrain est incorrect.\nVeuillez réessayer.", {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchUsers() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "http://localhost:8080")

        const response = await fetch(`${config.apiUri}/users`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()
      } catch (e) {
        console.log(e)
      }
    },
    async fetchIP() {
      this.loading = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "http://localhost:8080")

        const response = await fetch(`${config.apiUri}/users`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()
      } catch (e) {
        console.log(e)
      }
    },
    // async createSponsoredAccount(data) {
    //   const formData = new FormData()
    //   formData.append("firstname", data.firstname)
    //   formData.append("lastname", data.lastname)
    //   formData.append("phone", data.phoneNumber)
    //   formData.append("email", data.email)
    //   formData.append("password", data.password)
    //   formData.append("idCustomer", data.idCustomer)
    //   try {

    //     const response = fetch(
    //     `${config.apiUri}/php/pages/customer_professional_employee.php`, {
    //       method: "POST",
    //       body: formData,
    //     })

    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    async verifySponsorCodeConnection(sponsorCode) {
      await this.$store.dispatch("loginGuest", { sponsorCode: sponsorCode })
    },
    async getGrade(userID) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "http://localhost:8080")

        let response
        if (localStorage.getItem("linked") != null) {
          let accountId = localStorage.getItem("master_account_linked")
          response = await fetch(`${config.apiUri}/accounts/${userID}/grades`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        } else {
          response = await fetch(`${config.apiUri}/users/${userID}/grades`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        }

        const data = await response.json()
        return data[0]
      } catch (e) {
        console.log(e)
      }
    },
    applyDataStorageSponsor(sessionExpire, company, id, reduction, agency_name) {
      this.createCookie("expiration", true, sessionExpire)
      localStorage.setItem("company_name", company)
      // localStorage.setItem("lastname", lastname)
      // localStorage.setItem("firstname", firstname)
      // localStorage.setItem("complete_name", firstname + " " + lastname)
      // localStorage.setItem("email", email)
      localStorage.setItem("id_sponsor", id)
      localStorage.setItem("grade", "guest")
      localStorage.setItem("reduction", reduction)
      localStorage.setItem("agency", agency_name)
    },
    applyDataStorage(sessionExpire, user, jwt) {
      this.createCookie("expiration", true, sessionExpire)
      this.createCookie("jwt", jwt, sessionExpire)

      switch (this.userTypeChoose) {
        case "insurer":
          localStorage.setItem("complete_name", user.name)
          localStorage.setItem("is_available", user.available)
          localStorage.setItem("lastname", user.lastname)
          localStorage.setItem("firstname", user.firstname)
          localStorage.setItem("user_id", user.id)
          localStorage.setItem("agency", user.agency_name)
          localStorage.setItem("agency_id", user.agency_id)
          localStorage.setItem("email", user.email)
          localStorage.setItem("grade", user.grade)
          localStorage.setItem("phone", user.phone_number)
          localStorage.setItem("is_verify", parseInt(user.is_verify))
          localStorage.setItem("update_time", this.date + " " + this.time)
          localStorage.setItem("agent_for", user.insurer_name)
          localStorage.setItem("data_save", this.saveData)

          break
        case "social":
          localStorage.setItem("complete_name", this.userData.name)
          localStorage.setItem("lastname", this.userData.lastname)
          localStorage.setItem("firstname", this.userData.firstname)
          localStorage.setItem("user_id", this.userData.id)
          localStorage.setItem("agency", this.userData.agency_name)
          localStorage.setItem("agency_id", this.userData.id_agency)
          localStorage.setItem("email", this.userData.email)
          localStorage.setItem("grade", this.gradeData)
          break
      }
    },

    //! Check if we use localstorage or cookie for more secure...
    createCookie(name, value, minutes) {
      if (minutes) {
        var date = new Date()
        date.setTime(date.getTime() + minutes * 60 * 1000)
        var expires = "; expires=" + date.toGMTString()
      } else {
        var expires = ""
      }
      document.cookie = name + "=" + value + expires + "; path=/"
    },

    async connect(email, password) {
      this.loadingAccount = true

      try {
        const response = await fetch(`${config.apiUri}/auth/login`, {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ email: email, password: password, type: this.userTypeChoose }),
        })

        if (response.ok) {
          const data = await response.json()

          if (data.new) {
            this.userData.pincode = null
            this.userData.userId = data.userId
          } else {
            this.userData.pincode = ""
            this.userData.userId = data.userId
          }

          this.nextStep(2)
        } else if (response.status === 403) {
          this.$toast.warning(`Compte désactivé`, {
            position: "bottom-right",
            timeout: 3000,
          })
        } else {
          this.$toast.error(`Email ou mot de passe incorrect`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } catch (error) {
        console.error("Erreur lors de la connexion:", error)
        this.$toast.error(`Connexion impossible. ${error.message}\nVeuillez réessayer.`, {
          position: "bottom-right",
          timeout: 3000,
        })
        
      } finally {
        this.loadingAccount = false
      }
    },

    addShadow() {
      this.$refs.card.classList.add("elevation-10")
      console.log("add shadow")
    },
    removeShadow() {
      this.$refs.card.classList.remove("elevation-10")
    },
  },
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref("")
    const password = ref("")
    const socialLink = [
      {
        icon: mdiFacebook,
        color: "#4267b2",
        colorInDark: "#4267b2",
      },
      {
        icon: mdiGoogle,
        color: "#db4437",
        colorInDark: "#db4437",
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";

.card-with-shadow {
  position: relative;
}
.v-sheet.v-card {
  border-radius: 0px;
}

.card-with-shadow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: #000; /* Couleur de l'ombre */
  transition: opacity 0.3s ease-in-out; /* Animation de transition */
}

.card-with-shadow:hover::before {
  opacity: 0.3; /* Opacité de l'ombre au survol */
}
.card-footer {
}
</style>
